import React, { Component } from "react";
import { applyVueInReact, connectVuex } from "vuereact-combined";
import axios from "@/axios";
import LoadingSpinner from "@/components/LoadingSpinner";
import Payment from "../../components/payment/Payment";

class InternetBillCosmicnet extends Component {
  LoadingSpinnerComp = applyVueInReact(LoadingSpinner);
  constructor() {
    super();
    this.state = {
      loading: true,
      message: "",
      showReports: false,
      subscriber: "",
      phone: "",
      address: "",
      selectedPackage: {},
      packages: [],
      months: [],
      selectedMonth: {},
      billAmount: 0,
    };
  }
  componentDidMount() {
    axios.get("api/v2/services/cosmicnet").then((data) => {
      this.setState({
        packages: data.data.response.Packages,
        months: data.data.response.Option.Months,
        loading: false,
      });
    });
  }
  handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "phone") {
      const regx = /^$|^[0-9]+$/;
      if (!regx.test(value)) {
        return;
      }
    }
    if (name === "selectedMonth" || name === "selectedPackage") {
      value = value ? JSON.parse(value) : "";
    }
    this.setState({ [name]: value });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      billAmount:
        this.state.selectedPackage.Amount *
        parseFloat(this.state.selectedMonth.Value),
      showReports: true,
    });
  };

  render() {
    return (
      <div className="container">
        <div className="wallet-form-box card">
          <div className="card-body">
            <h5 className="card-title">Cosmicnet Payment</h5>
            <a href="/" className="btn btn-close">
              <i className="fa fa-times"></i>
            </a>
            {this.state.message && (
              <div className="form-group fade-animation">
                <div className="show-message">{this.state.message}</div>
              </div>
            )}
            <div className="row">
              <div className="col-md-7">
                {this.state.showReports ? (
                  <div className="fade-animation">
                    <div className="mt-4">
                      <div className="font-weight-bold">
                        User name / User id
                      </div>
                      <div>{this.state.subscriber}</div>
                    </div>
                    <div className="mt-4">
                      <div className="font-weight-bold">Mobile Number</div>
                      <div>{this.state.phone}</div>
                    </div>
                    <div className="mt-4">
                      <div className="font-weight-bold">Address</div>
                      <div>{this.state.address}</div>
                    </div>
                    <div className="mt-4">
                      <div className="font-weight-bold">Package</div>
                      <div>{this.state.selectedPackage.Name}</div>
                    </div>
                    <div className="mt-4">
                      <div className="font-weight-bold">Month</div>
                      <div>{this.state.selectedMonth.Value}</div>
                    </div>
                    <div className="mt-4">
                      <div className="font-weight-bold">Bill Amount</div>
                      <div>Rs. {this.state.billAmount}</div>
                    </div>
                    {/* PAYMENT DIVISION */}
                    <Payment
                      paymentUri="api/v4/cosmicnet/payment"
                      paymentPayload={{
                        Amount: this.state.billAmount,
                        UserId: this.state.subscriber,
                        PackageCode: this.state.selectedPackage.Code,
                        Month: this.state.selectedMonth.Value,
                        Name: this.state.selectedPackage.Name,
                        Address: this.state.address,
                      }}
                      setMessage={(message) => {
                        this.setState({ message });
                      }}
                      paymentPreviewDetail={{
                        "Customer Name": this.state.subscriber,
                        "Total Amount to Pay": "Rs. " + this.state.billAmount,
                      }}
                    />
                  </div>
                ) : (
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group mt-2">
                      <label>UserName / User Id</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Type Username and press enter."
                        name="subscriber"
                        onChange={this.handleChange}
                        value={this.state.subscriber}
                        required
                      />
                    </div>
                    <div className="form-group mt-2">
                      <label>Mobile Number</label>
                      <input
                        className="form-control"
                        type="tel"
                        placeholder="Type Phone Number and press enter."
                        maxLength={10}
                        minLength={10}
                        name="phone"
                        onChange={this.handleChange}
                        value={this.state.phone}
                        required
                      />
                    </div>
                    <div className="form-group mt-2">
                      <label>Address</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Type Adress and press enter."
                        name="address"
                        onChange={this.handleChange}
                        value={this.state.address}
                        required
                      />
                    </div>

                    {/* PACKAGE SELECTION */}
                    <div className="form-group mt-3">
                      <label>Select Package</label>
                      <select
                        className="custom-select"
                        name="selectedPackage"
                        onChange={this.handleChange}
                        required
                      >
                        <option value="">-- SELECT PACKAGE --</option>
                        {this.state.packages.map((packageData) => {
                          return (
                            <option
                              key={packageData.Code}
                              value={JSON.stringify(packageData)}
                            >
                              {packageData.Name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    {/* MONTH SELECTION */}
                    <div className="form-group mt-2">
                      <label>Select Month</label>
                      <select
                        className="custom-select"
                        name="selectedMonth"
                        onChange={this.handleChange}
                        required
                      >
                        <option value="">-- SELECT MONTH --</option>
                        {this.state.months.map((month) => {
                          return (
                            <option
                              key={month.Value}
                              value={JSON.stringify(month)}
                            >
                              {month.Text}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <button className="btn btn-primary mt-2" type="submit">
                      Search
                    </button>
                  </form>
                )}
              </div>
            </div>
            <this.LoadingSpinnerComp loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}

export default InternetBillCosmicnet;
