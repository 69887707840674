import React, { Component } from "react";
import { applyVueInReact, connectVuex } from "vuereact-combined";
import ShowBank from "@/components/ShowBank";
import PinConfirm from "@/components/PinConfirm";
import LoadingSpinner from "@/components/LoadingSpinner";
import api from "../../helpers/api";
import axios from "@/axios";

/*
ABOUT THIS Component :-
  This is a reusable component for making payment in react component.
  Props used:-
    paymentUri :- uri for making payment.
    paymentPaload :- payload for making payment.
    setMessage :- This is a function which sets the message state in the parent component.
    validationBeforePayment :- This is a function which is required for some serviced which requires validation check after bankClick function.
                               Note (Not compulsory)
    paymentPreviewDetail :- This is a object which is passed in previewDetail.
    generateTicket :- This is a boolean value. True some services which requires ticket to be generated after payment.
*/

class Payment extends Component {
  ShowBankComp = applyVueInReact(ShowBank);
  PinConfirmComp = applyVueInReact(PinConfirm);
  LoadingSpinnerComp = applyVueInReact(LoadingSpinner);

  constructor() {
    super();
    this.state = {
      loading: false,
      reward: 0,
      paymentModal: false,
      pin: "",
      banks: "",
      pinVerified: false,
    };
  }

  componentDidMount() {
    const loadBankAndService = async () => {
      this.setState({
        banks: await api.banks(),
        // serviceId: await api.serviceIdBySlug(this.props.serviceSlug),
      });
    };
    loadBankAndService();
    this.setState({ loading: false });
  }
  makePayment = () => {
    //SET MESSAGE TO "" THE SET MESSAGE SHOULD BE PASSED FROM THE PARENT COMPONENT.
    this.props.setMessage("");
    //VALIDATION BEFORE PAYMENT SHOULD BE PASSED FROM PARENT COMPONENT.
    this.setState({ paymentModal: true });
    if (this.state.pinVerified) {
      this.setState({
        paymentModal: false,
        pinVerified: false,
        loading: true,
      });
      axios
        .post(this.props.paymentUri, {
          ...this.props.paymentPayload,
          pin: this.state.pin,
        })
        .then((res) => {
          if (res.data) {
            if (res.data.status === 1) {
              // SET MESSAGE FROM PARENT COMPOENT
              this.props.setMessage(res.data.message);
              //UPDATE BALANCE.
              this.props.vuexDispatch("UPDATE_BALANCE");
              if (this.props.generateTicket) {
                const transaction = res.data.data._id;
                const ref = res.data.data.ref;
                axios
                  .get(`api/v4/transactions/export/${transaction}/${ref}`, {
                    responseType: "blob",
                  })
                  .then((response) => {
                    const ticketUrl = URL.createObjectURL(response.data);
                    window.open(ticketUrl);
                  });
              }
              if (this.props.serviceSlug === "PaymentIntent") {
                this.props.setMessage(
                  res.data.message + ". Redirecting to vendor page"
                );
                setTimeout(() => {
                  if (res.data.data && res.data.data.redirectUrl) {
                    window.location = res.data.data.redirectUrl;
                  }
                }, 1000);
              }
            } else {
              //SET MESSAGE FROM PARENT COMPONENT
              if (res.data?.data?.response[0]?.Message) {
                this.props.setMessage(res.data.data.response[0].Message);
              } else {
                this.props.setMessage(res.data.message);
              }
            }
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({
            loading: false,
            pinVerified: false,
            paymentModal: false,
          });
          //SET MESSAGE FROM PARENT COMPOENT
          this.props.setMessage("Something went wrong.");
        });
    }
  };
  bankClicked = () => {
    // this.setState({ bank }) -> NEW SERVICE INTEGRATION DONOT NEED THIS.
    if (this.props.validationBeforePayment) {
      if (!this.props.validationBeforePayment()) {
        return; // Validation failed, return early
      }
    }
    this.makePayment();
  };

  pinVerified(cb, verified) {
    if (verified) {
      this.setState({ pinVerified: true, pin: verified }, () => {
        // cb(verified);
        cb();
      });
    } else {
      this.setState({ pinVerified: false, paymentModal: false });
    }
  }
  verifyPin = (verified) => {
    this.pinVerified(this.makePayment, verified);
  };

  previewDetails = () => {
    return {
      ...this.props.paymentPreviewDetail,
    };
  };

  render() {
    return (
      <>
        {/* AFTER BANKS IS LOADED IN THE banks STATE THEN IT RENDERS THE COMPONENT */}
        {this.state.banks && (
          <>
            <div className="fade-animation">
              <this.ShowBankComp
                bankClicked={this.bankClicked}
                banks={this.state.banks}
              />

              {this.state.paymentModal && (
                <this.PinConfirmComp
                  previewDetails={this.previewDetails()}
                  pinVerification={this.verifyPin}
                />
              )}

              {this.state.loading && (
                <this.LoadingSpinnerComp loading={this.state.loading} />
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    vuexDispatch: (actionName) => {
      return dispatch(actionName);
    },
  };
};

export default connectVuex({
  mapDispatchToProps,
})(Payment);
